:root {
  --black: #333;
  --blue: #367ad4;
  --backgroundblue: #f5f8ff;
  --turquoise: #26B0B7;
  --orange: #f4a33b;
  --backgroundorange: #fffaf5;
  --lightorange: #ffb454;
  --red: #f25c5c;
  --lightgreen: #3bf48b;
  --green: #1eb25e;
  --backgroundgreen: #E5FFEF;
  --backgroundgray: #F3F4F6;
  --lightgray: #f1f1f1;
  --mediumlightgray: #898989;
  --gray: #5b5b5b;
  --darkgray: #3B3B3B;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
@media (min-width: 993px) {
  .show-mobile {
    display: none !important;
  }
}
@media (max-width: 992px) {
  .hide-mobile {
    display: none !important;
  }
}
button {
  cursor: pointer;
}
button:active,
button:focus {
  outline: none;
}
.clear-height {
  clear: both;
}
#background-fade {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
  z-index: 2;
  pointer-events: none;
  transition: opacity 0.3s;
}
#background-fade.fade {
  opacity: 1;
  pointer-events: auto;
}
#scroll-top {
  position: fixed;
  right: 10px;
  bottom: 10px;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  border: 2px solid var(--black);
  background: var(--orange);
  font-size: 30px;
  background-color: white;
  transition: bottom 0.1s, right 0.1s, height 0.1s, width 0.1s;
  z-index: 2;
}
#scroll-top:active {
  bottom: 12px;
  right: 12px;
  height: 46px;
  width: 46px;
}
#scroll-top.hidden {
  bottom: -60px;
}
#scroll-top > img {
  max-height: 100%;
  max-width: 100%;
  padding: 15px 10px 10px;
}
#mobile-logo {
  position: absolute;
  top: 10px;
  left: 10px;
  height: 50px;
  z-index: 3;
}
#mobile-logo > img {
  height: 100%;
  transition: opacity 0.3s;
}
#mobile-logo > img.fade {
  opacity: 0;
}
.left-col {
  float: left;
  width: 60%;
}
@media (max-width: 992px) {
  .left-col {
    width: 100% !important;
  }
}
.right-col {
  float: right;
  width: calc(40% - 30px);
  padding-left: 50px;
}
@media (max-width: 992px) {
  .right-col {
    padding-left: 0;
  }
}
@font-face {
  font-family: 'impactreg';
  font-display: fallback;
  src: url('/font/impactreg.eot');
  src: url('/font/impactreg.woff') format('woff'), url('/font/impactreg.ttf') format('truetype');
}
@font-face {
  font-family: magnolia-script;
  src: url('/font/magnolia_script.otf');
}
li,
footer nav a,
footer nav p {
  display: list-item;
  margin-left: 20px;
}
p a {
  display: inline;
}
p span {
  display: inline;
  font-weight: bold;
}
h1,
h2,
h3,
h4,
h5,
h6,
p.heading,
strong.heading,
a.heading {
  font-family: 'impactreg', sans-serif;
  font-weight: normal;
  color: var(--darkgray);
  letter-spacing: 1px;
  text-decoration: none;
}
p.heading {
  font-size: 30px;
}
p,
a,
input,
textarea,
li,
strong {
  font-family: segoe ui, sans-serif;
  line-height: 26px;
}
li {
  margin-top: 10px;
}
h1 {
  font-size: 40px;
  text-align: center;
}
h2 {
  font-size: 30px;
}
h3 {
  font-size: 22px;
}
p.paragraph {
  margin-top: 15px !important;
  line-height: 26px;
}
.block {
  display: block;
}
#site-header {
  position: absolute;
  height: 100px;
  width: 100%;
  z-index: 2;
}
@media (max-width: 992px) {
  #site-header {
    position: fixed;
  }
}
#site-header button.show-mobile:first-of-type {
  position: fixed;
  top: 10px;
  right: 10px;
  width: 50px;
  height: 50px;
  border: 3px solid var(--gray);
  background-color: white;
  z-index: 2;
  transition: height 0.1s, width 0.1s, top 0.1s, right 0.1s;
}
#site-header button.show-mobile:first-of-type:active {
  height: 46px;
  width: 46px;
  top: 12px;
  right: 12px;
}
#site-header button.show-mobile:first-of-type #border-container {
  display: block;
  position: relative;
  height: 60%;
  width: 80%;
  margin: auto;
}
#site-header button.show-mobile:first-of-type #border-container span {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  height: 0;
  border: 2px solid var(--gray);
  border-radius: 2px;
  transition: transform 0.2s, left 0.2s, right 0.2s, opacity 0.18s;
}
#site-header button.show-mobile:first-of-type #border-container span#border-top {
  top: 0;
}
#site-header button.show-mobile:first-of-type #border-container span#border-middle-1,
#site-header button.show-mobile:first-of-type #border-container span#border-middle-2 {
  top: 0;
  bottom: 0;
}
#site-header button.show-mobile:first-of-type #border-container span#border-bottom {
  bottom: 0;
}
#site-header button.show-mobile:first-of-type #border-container span#border-top.anim,
#site-header button.show-mobile:first-of-type #border-container span#border-bottom.anim {
  left: 50%;
  right: 50%;
  opacity: 0;
}
#site-header button.show-mobile:first-of-type #border-container span#border-middle-1.anim {
  transform: rotate(45deg);
}
#site-header button.show-mobile:first-of-type #border-container span#border-middle-2.anim {
  transform: rotate(-45deg);
}
#site-header nav a:first-child img {
  display: block;
  height: 100px;
  padding: 10px 0;
  margin: 0 auto;
}
#site-header nav a.logo {
  width: 100%;
}
#site-header nav > div {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
#site-header nav > div span.breakpoint {
  display: none;
}
#site-header nav > div > a,
#site-header nav > div > div.dropdown-container {
  display: flex;
  float: left;
  height: 100px;
  align-items: center;
  margin-left: 0;
  font-size: 22px;
  font-family: "impactreg";
  letter-spacing: 1px;
  text-decoration: none;
  text-align: center;
  color: white;
}
#site-header nav > div div.dropdown-container {
  position: relative;
}
#site-header nav > div div.dropdown-container:hover > ul.dropdown {
  opacity: 1 !important;
  pointer-events: auto !important;
  z-index: 1;
}
#site-header nav > div div.dropdown-container:hover > img {
  transform: rotate(180deg);
}
#site-header nav > div div.dropdown-container img {
  width: 12px;
  margin-left: 18px;
  transition: transform 0.16s;
}
#site-header nav > div div.dropdown-container button.dropdown,
#site-header nav > div div.dropdown-container a.dropdown {
  font-size: 22px;
  font-family: "impactreg";
  letter-spacing: 1px;
  color: white;
  background: none;
  outline: none;
  border: none;
  text-decoration: none;
}
#site-header nav > div div.dropdown-container ul.dropdown {
  position: absolute;
  top: 75px;
  left: 50%;
  transform: translate(-52%, 0);
  width: 200px;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.3);
  pointer-events: none;
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.16s;
}
#site-header nav > div div.dropdown-container ul.dropdown > li {
  display: block;
  margin: 0 10px;
  border-bottom: 1px solid lightgray;
}
#site-header nav > div div.dropdown-container ul.dropdown > li:last-of-type {
  border: none;
}
#site-header nav > div div.dropdown-container ul.dropdown > li:nth-child(even) > a {
  background-color: #e8e8e8;
}
#site-header nav > div div.dropdown-container ul.dropdown > li a {
  display: block;
  padding: 10px;
  margin: -1px -10px;
  color: #333;
  font-size: 18px;
  text-decoration: none;
}
#site-header nav > div div.dropdown-container ul.dropdown > li a:hover {
  background-color: lightgray;
}
@media (max-width: 1200px) AND (min-width: 992px) {
  #site-header nav > div {
    margin-top: 25px;
  }
  #site-header nav > div span.breakpoint {
    display: block;
    width: 100%;
  }
  #site-header nav > div > a,
  #site-header nav > div div.dropdown-container {
    height: 55px;
    padding: 0 10px;
  }
  #site-header nav > div ul.dropdown {
    top: 50px !important;
  }
}
@media (max-width: 992px) {
  #site-header nav {
    position: fixed;
    top: 0;
    bottom: 0;
    right: -300px;
    width: 300px;
    background-color: white;
    transition: right 0.3s;
  }
  #site-header nav.expand {
    right: 0;
  }
  #site-header nav img {
    height: 50px !important;
    margin: 0 !important;
    padding: 0 !important;
  }
  #site-header nav > div {
    display: block;
    max-height: calc(100% - 80px);
    overflow: auto;
  }
  #site-header nav div.dropdown-container {
    display: block !important;
    height: auto !important;
    float: none !important;
  }
  #site-header nav div.dropdown-container > button {
    display: none !important;
  }
  #site-header nav div.dropdown-container ul.dropdown {
    position: static !important;
    display: block !important;
    width: auto !important;
    transform: translate(0, 0) !important;
    left: 0 !important;
    opacity: 1 !important;
    border-radius: 0 !important;
    box-shadow: none !important;
  }
  #site-header nav div.dropdown-container ul.dropdown > li {
    margin: 0 !important;
    border: none !important;
  }
  #site-header nav div.dropdown-container ul.dropdown > li > a {
    padding: 0 !important;
    font-size: 22px !important;
    font-family: "impactreg" !important;
  }
  #site-header nav div.dropdown-container img {
    display: none;
  }
  #site-header nav > a {
    margin-bottom: 15px;
    padding: 10px 0 10px 10px !important;
    background-color: var(--blue);
  }
  #site-header nav a {
    display: block !important;
    float: none !important;
    height: auto !important;
    margin: 0 !important;
    padding: 0;
    color: var(--black) !important;
    text-align: left !important;
    text-decoration: none;
    color: var(--black);
    border-bottom: 1px solid lightgray !important;
  }
  #site-header nav a > span {
    background-color: white;
    display: block;
    width: calc(100% - 15px);
    line-height: 45px;
    margin-right: 0;
    margin-left: auto;
    padding-left: 15px;
  }
  #site-header nav a > span:hover {
    background-color: var(--lightgray);
  }
  #site-header nav a.recension {
    background-color: #d78c9e !important;
  }
  #site-header nav a.webbhotell {
    background-color: var(--blue) !important;
  }
  #site-header nav a.virtuell-server {
    background-color: var(--gray) !important;
  }
  #site-header nav a.dedikerad-server {
    background-color: var(--orange) !important;
  }
  #site-header nav a.kopa-domannamn {
    background-color: var(--turquoise) !important;
  }
  #site-header nav a.skapa-hemsida {
    background-color: #cc5454 !important;
  }
  #site-header nav a.blogg {
    background-color: var(--green) !important;
  }
  #site-header nav a.omoss {
    background-color: #2999b7 !important;
  }
}
footer {
  position: relative;
  background-color: var(--blue);
}
@media (max-width: 992px) {
  footer .container:first-of-type > div {
    margin: 20px 0;
  }
}
@media (max-width: 400px) {
  footer .bottom p {
    font-size: 12px;
  }
}
footer .hittasmslan {
  max-width: 300px;
}
footer .hittasmslan .text {
  font-size: 18px;
}
footer img {
  position: absolute;
  bottom: 65px;
  right: 15px;
  height: 120px;
}
@media (max-width: 576px) {
  footer img {
    display: none;
  }
}
footer > section > div > div > p {
  margin: 0 0 15px;
  font-size: 30px;
  color: white !important;
}
footer a,
footer nav p {
  padding: 5px 0;
  text-decoration: none;
  color: white;
}
footer a:hover {
  text-decoration: underline;
}
footer .bottom {
  clear: both;
  height: 50px;
  line-height: 50px;
  padding: 0px 15px;
  color: white;
  background-color: rgba(0, 0, 0, 0.25);
}
footer .bottom p:last-child {
  width: 100%;
  text-align: center;
  line-height: 50px;
}
footer .container:first-of-type {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
main {
  min-height: 100vh;
  overflow: hidden;
}
.container {
  margin: 0 auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 530px;
  }
}
@media (min-width: 828px) {
  .container {
    max-width: 780px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin: 50px -15px -15px;
}
section {
  padding: 50px 15px;
}
main section:first-child {
  padding-top: 225px;
  color: white;
}
@media (max-width: 1200px) AND (min-width: 992px) {
  main section:first-child {
    padding-top: 275px;
  }
}
@media (max-width: 992px) {
  main section:first-child {
    padding-top: 100px;
  }
}
.intro .row {
  margin: 0 -15px;
}
.intro > .row > .left {
  width: calc((100% / 12) * 7);
  padding: 0 45px 0 15px;
}
@media (max-width: 992px) {
  .intro > .row > .left {
    padding: 0 15px !important;
  }
}
.intro > .row > .left p {
  text-align: left;
}
@media (max-width: 992px) {
  .intro > .row > .left .heading {
    text-align: center;
  }
}
.intro > .row > .left .heading > * {
  display: inline;
  font-size: 50px;
  line-height: 50px;
  font-family: 'impactreg', sans-serif;
  letter-spacing: 1px;
  color: white;
}
@media (max-width: 992px) {
  .intro > .row > .left .heading > * {
    font-size: 35px;
  }
}
.intro > .row > .left .heading span {
  display: block;
  font-size: 34px;
  font-weight: normal;
  text-align: left;
}
@media (max-width: 992px) {
  .intro > .row > .left .heading span {
    text-align: center;
  }
}
.intro > .row > .left:first-child img {
  display: block;
  height: 140px;
  margin: 30px auto 0 auto;
  object-fit: contain;
}
.intro .right {
  position: relative;
  width: calc((100% / 12) * 5);
  padding: 0 15px 0 45px;
}
.intro .right img.icon {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  height: 200px;
}
.intro .right .card-small-webhotel {
  border: none;
}
@media (max-width: 992px) {
  .intro > .row > .left,
  .intro > .row > .right {
    width: 100% !important;
  }
  .intro .right {
    padding: 0 !important;
  }
}
.intro p {
  margin-top: 30px;
  line-height: 24px;
  font-size: 18px;
}
.site-index {
  padding-bottom: 50px;
  color: white;
}
.site-index .col {
  display: flex;
  flex-wrap: wrap;
}
.site-index .col > * {
  width: 50%;
}
.site-index .col > *:first-of-type {
  padding-right: 40px;
}
.site-index a {
  margin-bottom: 10px;
  font-size: 18px;
  text-decoration: none;
  color: white;
}
.site-index a:hover {
  text-decoration: underline;
}
#qa-container {
  position: relative;
  height: 130px;
}
#qa-container section {
  position: absolute;
  top: 0;
  width: 100%;
  padding-top: 30px;
  background: none;
  background-color: var(--gray);
  transition: bottom 0.3s;
  z-index: 1;
}
#qa-container ul {
  margin-left: 20px;
}
#qa-container ul li {
  color: var(--black);
}
#qa-container .text-subcontainer > a {
  margin: 15px;
}
#qa-container p.heading {
  margin-bottom: 50px;
  font-size: 30px;
  text-align: center;
  color: white;
}
#qa-container img.arrow {
  display: block;
  width: 50px;
  margin: 0 auto 20px auto;
  cursor: pointer;
  transition: transform 0.3s;
}
#qa-container img.arrow.flip {
  transform: rotate3d(1, 0, 0, 180deg);
}
#qa-container .column {
  float: left;
  width: 50%;
}
@media (max-width: 992px) {
  #qa-container .column {
    width: 100%;
  }
}
#qa-container p {
  color: var(--black);
}
.card-small-webhotel,
.card-medium-webhotel,
.card-question-anwser,
.card-webpackage,
.card-webpackage-specs,
.card-server {
  margin: 0 15px 30px 15px;
  border: 1px solid lightgray;
  box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.2);
}
@media (max-width: 413px) {
  main .card-small-webhotel,
  main .card-medium-webhotel,
  main .card-webpackage,
  main .card-webpackage-specs,
  main .card-server {
    padding: 15px;
  }
}
@media (max-width: 827px) {
  .card-small-webhotel,
  .card-medium-webhotel,
  main .card-question-anwser,
  main .card-webpackage,
  main .card-webpackage-specs,
  main .card-server {
    width: calc(100% - 30px);
  }
}
@media (max-width: 1200px) and (min-width: 828px) {
  .card-small-webhotel,
  .card-medium-webhotel,
  main .card-webpackage,
  main .card-webpackage-specs,
  main .card-server {
    width: calc((100% / 2) - 30px);
  }
}
@media (min-width: 1200px) {
  .card-small-webhotel,
  .card-medium-webhotel,
  main .card-webpackage,
  main .card-webpackage-specs,
  main .card-server {
    width: calc((100% / 3) - 30px);
  }
}
.card-medium-webhotel .over a:first-of-type,
.card-server .over a:first-of-type {
  float: left;
  height: 50px;
  max-width: 70%;
}
.card-medium-webhotel .over a:first-of-type > img,
.card-server .over a:first-of-type > img {
  height: 100%;
  max-width: 100%;
  object-fit: contain;
}
.card-medium-webhotel .over a:last-of-type,
.card-server .over a:last-of-type {
  line-height: 26px;
  font-weight: bold;
  color: var(--blue);
  border-bottom: 2px solid var(--blue);
  text-decoration: none;
}
.card-medium-webhotel .bottom,
.card-server .bottom {
  width: 100%;
  padding-top: 15px;
  border-top: 2px solid var(--orange);
}
.card-medium-webhotel a.order-link {
  float: left;
  width: 100%;
  margin-top: 15px;
  padding: 15px 0;
  font-size: 22px;
  text-align: center;
  text-decoration: none;
  color: white;
  background-color: var(--orange);
  border-radius: 5px;
  font-weight: bold;
  box-shadow: 0 5px 0 0 #866418;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
}
.card-webpackage,
.card-webpackage-specs,
.card-server {
  display: flex;
  flex-direction: column;
  padding: 15px;
  background-color: white;
  border-radius: 8px;
}
.card-webpackage div,
.card-webpackage-specs div,
.card-server .bottom {
  height: 100%;
}
.card-webpackage p.price,
.card-webpackage-specs p.price {
  margin-top: 25px;
}
main .card-webpackage h2,
main .card-webpackage-specs h2 {
  padding: 0;
}
.card-webpackage-specs p,
.card-server p {
  margin: 15px 0;
  text-align: left;
}
p.price {
  margin-bottom: 0;
  font-size: 21px;
  font-weight: bold;
  color: var(--red);
}
.card-webpackage p.price span,
.card-medium-webhotel p.price span,
.snippet-card p.price span {
  margin-left: 20px;
  text-decoration: line-through;
  color: gray;
}
.info {
  position: relative;
  padding-bottom: 25px;
}
.info.orange {
  background-color: var(--backgroundorange);
}
.info.blue {
  background-color: var(--backgroundblue);
}
.info.turquoise {
  background-color: var(--turquoise);
}
.info.green {
  background-color: var(--backgroundgreen);
}
.info.gray {
  background-color: var(--lightgray);
}
.info .title p,
.info .title h2,
.info .title h3,
.info .title h4 {
  padding: 50px 0 25px;
  font-size: 30px;
}
@media (max-width: 575px) {
  .info .title {
    margin: 0 15px;
  }
}
.info.orange .title {
  border-bottom: 2px solid var(--orange);
}
.info.orange .title p,
.info.orange .title h2,
.info.orange .title h3,
.info.orange .title h4 {
  color: var(--orange);
}
.info.blue .title {
  border-bottom: 2px solid var(--blue);
}
.info.blue .title p,
.info.blue .title h2,
.info.blue .title h3,
.info.blue .title h4 {
  color: var(--blue);
}
.info.green .title {
  border-bottom: 2px solid var(--green);
}
.info.green .title p,
.info.green .title h2,
.info.green .title h3,
.info.green .title h4 {
  color: var(--green);
}
.info.gray .title {
  border-bottom: 2px solid var(--gray);
}
.info.gray .title p,
.info.gray .title h2,
.info.gray .title h3,
.info.gray .title h4 {
  color: var(--gray);
}
.info p.desc {
  margin: 25px 0;
}
@media (max-width: 575px) {
  .info p.desc {
    padding: 0 15px;
  }
}
.info p {
  margin: 0 0 25px;
  line-height: 26px;
}
.info h3 {
  margin: 0 0 10px;
}
.info .container .row {
  margin: 25px -15px 0;
  padding-top: 15px;
}
.info .container .row .col {
  width: calc((100% / 2) - 30px);
  margin: 0 15px 0px 15px;
}
@media (max-width: 1199px) {
  .info .container .row .col {
    width: calc((100% / 2) - 30px);
  }
}
@media (max-width: 827px) {
  .info .container .row .col {
    width: 100%;
  }
}
.info .container .row .col p {
  text-align: justify;
}
@media (max-width: 1199px) {
  .info .container .row .col p {
    text-align: left;
  }
}
@media (max-width: 575px) {
  .info .container .row {
    margin: 25px 0 0;
  }
}
.card-small-webhotel {
  padding: 15px 30px;
  color: var(--black);
  background-color: white;
  border-radius: 8px;
  box-shadow: 3px 3px 20px 5px rgba(0, 0, 0, 0.15);
}
.card-small-webhotel .over a.info-link {
  float: right;
  line-height: 50px;
  font-weight: bold;
  color: var(--blue);
}
.card-small-webhotel .over p {
  float: left;
  clear: left;
  margin: 5px 0;
  font-size: 16px;
  text-align: left;
}
.card-small-webhotel .over p span,
.card-small-webhotel .over h2 {
  display: block;
  border-bottom: 2px solid var(--black);
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.card-small-webhotel .over h2 {
  clear: both;
  margin-bottom: -15px;
  margin-top: 45px;
  padding-top: 25px;
}
.card-small-webhotel .over p:first-of-type {
  margin: 15px 0 5px 0;
}
.card-small-webhotel .over p:last-of-type {
  margin: 25px 0;
}
.card-small-webhotel .left {
  float: left;
  width: auto;
  padding: 0;
  clear: left;
  text-align: left;
}
.card-small-webhotel .right {
  float: right;
  width: auto;
  padding: 0;
}
@media (max-width: 1200px) {
  .card-small-webhotel .right {
    width: 100%;
    margin-top: 20px;
    text-align: center;
  }
}
.card-small-webhotel .right a {
  display: block;
  padding: 10px 15px;
  font-size: 20px;
  color: white;
  background-color: var(--orange);
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  box-shadow: 0 5px 0 0 #866418;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
}
.card-small-webhotel .right p {
  margin-top: 15px;
  text-align: center;
  font-weight: bold;
  color: var(--red);
}
.card-small-webhotel .right p.old {
  margin-top: 5px;
  text-decoration: line-through;
  color: gray;
}
@media (max-width: 413px) {
  .card-small-webhotel .right {
    float: left;
    clear: left;
    width: 100%;
    margin-top: 10px;
  }
  .card-small-webhotel .right a {
    text-align: center;
  }
}
.card-small-webhotel .over img {
  float: left;
  height: 50px;
  max-width: 100%;
  object-fit: contain;
}
.checkbox-field {
  margin-bottom: 10px;
}
.checkbox {
  position: relative;
  display: inline-block;
  height: 30px;
  width: 30px;
  border: 3px solid gray;
  border-radius: 5px;
}
.checkbox img {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 35px;
  margin: auto;
}
.checkbox img.unchecked {
  top: 1px;
}
.checkbox-field p {
  display: inline-block;
  margin: 0 0 0 10px;
  line-height: 30px;
  font-size: 16px;
  vertical-align: top;
  color: var(--blue);
}
.card-medium-webhotel {
  display: flex;
  flex-direction: column;
  padding: 15px;
  text-align: left;
  color: var(--black);
  background-color: white;
  border-radius: 8px;
}
.card-medium-webhotel .over {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding-bottom: 10px;
}
.card-medium-webhotel .over img.corner-ribbon {
  position: absolute;
  left: -20px;
  width: 90px;
  top: -20px;
}
.card-medium-webhotel .over p.corner-ribbon {
  position: absolute;
  left: -23px;
  width: 90px;
  top: -12px;
  font-family: sans-serif;
  font-weight: bold;
  color: white;
  transform: rotate(-45deg);
}
.card-medium-webhotel .middle {
  height: 100%;
  width: 100%;
  margin-top: 10px;
  padding-bottom: 20px;
}
.card-medium-webhotel .middle p {
  margin: 0;
}
.card-medium-webhotel .middle p span,
.card-medium-webhotel .middle h2 {
  display: block;
  border-bottom: 2px solid var(--black);
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.card-small-webhotel .over h2,
.card-medium-webhotel .middle h2 {
  font-size: 16px;
  font-family: segoe ui, sans-serif !important;
  font-weight: bold;
  letter-spacing: 0px;
  text-align: left;
  color: var(--black);
}
.card-medium-webhotel .left {
  float: left;
}
.card-medium-webhotel .right {
  float: right;
}
.card-medium-webhotel .bottom {
  margin-bottom: 15px;
}
.card-medium-webhotel .bottom.noBorder {
  padding-top: 0;
  border: none;
}
.card-medium-webhotel .bottom.noBorder p.price {
  margin-top: 0;
}
.card-medium-webhotel .bottom.noBorderPadding {
  height: 100%;
  border: none;
}
.card-medium-webhotel .bottom p {
  line-height: 26px;
  margin: 0 0 5px;
}
.card-medium-webhotel .bottom p.price {
  margin-top: 20px;
}
.card-medium-webhotel .bottom .right {
  text-align: right;
}
@media (max-width: 413px) {
  .card-medium-webhotel .middle .right {
    float: left;
    clear: left;
  }
}
.card-question-anwser {
  padding: 0 0 20px 0;
  background-color: var(--orange);
  border-radius: 8px;
  transition: height 0.3s;
  cursor: pointer;
}
.card-question-anwser strong {
  display: block;
  position: relative;
  padding: 20px 30px 0 30px;
  text-align: center;
  font-size: 22px;
  color: white;
  transition: padding 0.3s;
  user-select: none;
}
.card-question-anwser strong img {
  position: absolute;
  top: 28px;
  right: 15px;
  height: 12px;
  transition: transform 0.2s;
}
.card-question-anwser strong img.rotate {
  transform: rotate(180deg);
}
.card-question-anwser .text-container {
  position: relative;
  height: 0px;
  background-color: white;
  transition: height 0.3s;
  overflow: hidden;
  cursor: initial;
}
.card-question-anwser .text-container .text-subcontainer {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.card-question-anwser .text-container .text-subcontainer p {
  margin: 15px;
  line-height: 22px;
}
.info .container .row .card-webpackage div p,
.card-webpackage div p {
  margin-top: 15px;
}
.trustpilot-container {
  margin-top: 100px;
}
@media (max-width: 992px) {
  .trustpilot-container {
    margin-top: 50px;
  }
}
