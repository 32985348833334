// Variables
@import 'globals.less';

:root {
	--black: #333;
	--blue: #367ad4;
	--backgroundblue: #f5f8ff;
	--turquoise: #26B0B7;
	--orange: #f4a33b;
	--backgroundorange: #fffaf5;
	--lightorange: #ffb454;
	--red: #f25c5c;
	--lightgreen: #3bf48b;
	--green: #1eb25e;
	--backgroundgreen: #E5FFEF;
	--backgroundgray: #F3F4F6;
	--lightgray: #f1f1f1;
	--mediumlightgray: #898989;
	--gray: #5b5b5b;
	--darkgray: #3B3B3B;
}
// }}}
// Misc {{{
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

@media (min-width: @media_m + 1) {
	.show-mobile {
		display: none !important;
	}
}

@media (max-width: @media_m) {
	.hide-mobile {
		display: none !important;
	}
}

button {
	cursor: pointer;

	&:active, &:focus {
		outline: none;
	}
}

.clear-height {
	clear: both;
}

#background-fade {
	position: fixed;
	top: 0; bottom: 0; right: 0; left: 0;
	background-color: rgba(0, 0, 0, 0.6);
	opacity: 0;
	z-index: 2;
	pointer-events: none;
	transition: opacity 0.3s;

	&.fade {
		opacity: 1;
		pointer-events: auto;
	}
}

#scroll-top {
    position: fixed;
    right: 10px;
    bottom: 10px;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    border: 2px solid var(--black);
	background: var(--orange);
    font-size: 30px;
	background-color: white;
    transition: bottom 0.1s, right 0.1s, height 0.1s, width 0.1s;
	z-index: 2;

	&:active {
		bottom: 12px; right: 12px;
		height: 46px;
		width: 46px;
	}

	&.hidden {
		bottom: -60px;
	}

	> img {
		max-height: 100%;
		max-width: 100%;
		padding: 15px 10px 10px;
	}
}

#mobile-logo {
	position: absolute;
	top: 10px; left: 10px;
	height: 50px;
	z-index: 3;

	> img {
		height: 100%;
		transition: opacity 0.3s;

		&.fade {
			opacity: 0;
		}
	}
}

.left-col {
	float: left;
	width: 60%;

	@media (max-width: @media_m) {
		& {
			width: 100% !important;
		}
	}
}

.right-col {
	float: right;
	width: calc(40% - 30px);
	padding-left: 50px;

	@media (max-width: @media_m) {
		& {
			padding-left: 0;
		}
	}
}
// }}}
// Text {{{
@font-face {
	font-family: 'impactreg';
	font-display: fallback;
	src: url('/font/impactreg.eot');
	src: url('/font/impactreg.woff') format('woff'), url('/font/impactreg.ttf') format('truetype');
}

@font-face {
	font-family: magnolia-script;
	src: url('/font/magnolia_script.otf');
}

li, footer nav a, footer nav p {
	display: list-item;
	margin-left: 20px;
}

p a {
	display: inline;
}

p span {
	display: inline;
	font-weight: bold;
}

h1, h2, h3, h4, h5, h6, p.heading, strong.heading, a.heading {
	font-family: 'impactreg', sans-serif;
	font-weight: normal;
	color: var(--darkgray);
	letter-spacing: 1px;
	text-decoration: none;
}

p.heading {
	font-size: 30px;
}

p, a, input, textarea, li, strong {
	font-family: segoe ui, sans-serif;
	line-height: 26px;
}

li {
	margin-top: 10px;
}

h1 {
	font-size: 40px;
	text-align: center;
}

h2 {
	font-size: 30px;
}

h3 {
	font-size: 22px;
}

p.paragraph {
	margin-top: 15px !important;
	line-height: 26px;
}

.block {
	display: block;
}
// }}}
// Site-header {{{
#site-header {
	position: absolute;
	height: 100px;
	width: 100%;
	z-index: 2;

	@media (max-width: @media_m) {
		& {
			position: fixed;
		}
	}

	button.show-mobile:first-of-type {
		position: fixed;
		top: 10px; right: 10px;
		width: 50px;
		height: 50px;
		border: 3px solid var(--gray);
		background-color: white;
		z-index: 2;
		transition: height 0.1s, width 0.1s, top 0.1s, right 0.1s;

		&:active {
			height: 46px;
			width: 46px;
			top: 12px; right: 12px;
		}

		#border-container {
			display: block;
			position: relative;
			height: 60%;
			width: 80%;
			margin: auto;

			span {
				position: absolute;
				left: 0; right: 0;
				margin: auto;
				height: 0;
				border: 2px solid var(--gray);
				border-radius: 2px;
				transition: transform 0.2s, left 0.2s, right 0.2s, opacity 0.18s;

				&#border-top {
					top: 0;
				}

				&#border-middle-1, &#border-middle-2 {
					top: 0; bottom: 0;
				}

				&#border-bottom {
					bottom: 0;
				}

				&#border-top.anim, &#border-bottom.anim {
					left: 50%;
					right: 50%;
					opacity: 0;
				}

				&#border-middle-1.anim {
					transform: rotate(45deg);
				}

				&#border-middle-2.anim {
					transform: rotate(-45deg);
				}
			}
		}
	}

	nav {
		a {
			&:first-child {
				img {
					display: block;
					height: 100px;
					padding: 10px 0;
					margin: 0 auto;
				}
			}

			&.logo {
				width: 100%;
			}
		}

		> div {
			display: flex;
			justify-content: space-between;
			width: 100%;

			span.breakpoint {
				display: none;
			}

			> a,
			> div.dropdown-container {
				display: flex;
				float: left;
				height: 100px;
				align-items: center;
				margin-left: 0;
				font-size: 22px;
				font-family: "impactreg";
				letter-spacing: 1px;
				text-decoration: none;
				text-align: center;
				color: white;
			}

			div.dropdown-container {
				position: relative;

				&:hover > ul.dropdown {
					opacity: 1 !important;
					pointer-events: auto !important;
					z-index: 1;
				}

				&:hover > img {
					transform: rotate(180deg);
				}

				img {
					width: 12px;
					margin-left: 18px;
					transition: transform 0.16s;
				}

				button.dropdown,
				a.dropdown {
					font-size: 22px;
					font-family: "impactreg";
					letter-spacing: 1px;
					color: white;
					background: none;
					outline: none;
					border: none;
					text-decoration: none;
				}

				ul.dropdown {
					position: absolute;
					top: 75px; left: 50%;
					transform: translate(-52%, 0);
					width: 200px;
					border-radius: 5px;
					background-color: white;
					box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.3);
					pointer-events: none;
					overflow: hidden;
					opacity: 0;
					transition: opacity 0.16s;

					> li {
						display: block;
						margin: 0 10px;
						border-bottom: 1px solid lightgray;

						&:last-of-type {
							border: none;
						}

						&:nth-child(even) > a {
							background-color: #e8e8e8;
						}

						a {
							display: block;
							padding: 10px;
							margin: -1px -10px;
							color: #333;
							font-size: 18px;
							text-decoration: none;

							&:hover {
								background-color: lightgray;
							}
						}
					}
				}
			}
		}

		@media (max-width: @media_l) AND (min-width: @media_m) {
			> div {
				margin-top: 25px;

				span.breakpoint {
					display: block;
					width: 100%;
				}

				> a, div.dropdown-container {
					height: 55px;
					padding: 0 10px;
				}

				ul.dropdown {
					top: 50px !important;
				}
			}
		}

		@media (max-width: @media_m) {
			position: fixed;
			top: 0; bottom: 0; right: -300px;
			width: 300px;
			background-color: white;
			transition: right 0.3s;

			&.expand {
				right: 0;
			}

			img {
				height: 50px !important;
				margin: 0 !important;
				padding: 0 !important;
			}

			> div {
				display: block;
				max-height: calc(100% - 80px);
				overflow: auto;
			}

			div.dropdown-container {
				display: block !important;
				height: auto !important;
				float: none !important;

				> button {
					display: none !important;
				}

				ul.dropdown {
					position: static !important;
					display: block !important;
					width: auto !important;
					transform: translate(0, 0) !important;
					left: 0 !important;
					opacity: 1 !important;
					border-radius: 0 !important;
					box-shadow: none !important;

					> li {
						margin: 0 !important;
						border: none !important;

						> a {
							padding: 0 !important;
							font-size: 22px !important;
							font-family: "impactreg" !important;
						}
					}
				}

				img {
					display: none;
				}
			}

			& > a {
				margin-bottom: 15px;
				padding: 10px 0 10px 10px !important;
				background-color: var(--blue);
			}

			a {
				display: block !important;
				float: none !important;
				height: auto !important;
				margin: 0 !important;
				padding: 0;
				color: var(--black) !important;
				text-align: left !important;
				text-decoration: none;
				color: var(--black);
				border-bottom: 1px solid lightgray !important;

				> span {
					background-color: white;
					display: block;
					width: calc(100% - 15px);
					line-height: 45px;
					margin-right: 0;
					margin-left: auto;
					padding-left: 15px;

					&:hover {
						background-color: var(--lightgray);
					}
				}

				&.recension {
					background-color: #d78c9e !important;
				}

				&.webbhotell {
					background-color: var(--blue) !important;
				}

				&.virtuell-server {
					background-color: var(--gray) !important;
				}

				&.dedikerad-server {
					background-color: var(--orange) !important;
				}

				&.kopa-domannamn {
					background-color: var(--turquoise) !important;
				}

				&.skapa-hemsida {
					background-color: #cc5454 !important;
				}

				&.blogg {
					background-color: var(--green) !important;
				}

				&.omoss {
					background-color: #2999b7 !important;
				}
			}
		}
	}
}
// }}}
// Site-footer {{{
footer {
	position: relative;
	background-color: var(--blue);

	@media (max-width: @media_m) {
		.container:first-of-type > div {
			margin: 20px 0;
		}
	}

	@media (max-width: @media_xs) {
	}

	@media (max-width: 400px) {
		.bottom p {
			font-size: 12px;
		}
	}

	.hittasmslan {
		max-width: 300px;

		.text {
			font-size: 18px;
		}
	}

	img {
		position: absolute;
		bottom: 65px; right: 15px;
		height: 120px;

		@media (max-width: @media_xs) {
			& {
				display: none;
			}
		}
	}

	> section > div > div > p {
		margin: 0 0 15px;
		font-size: 30px;
		color: white !important;
	}

	a, nav p {
		padding: 5px 0;
		text-decoration: none;
		color: white;
	}

	a:hover {
		text-decoration: underline;
	}

	.bottom {
		clear: both;
		height: 50px;
		line-height: 50px;
		padding: 0px 15px;
		color: white;
		background-color: rgba(0, 0, 0, 0.25);

		p:last-child {
			width: 100%;
			text-align: center;
			line-height: 50px;
		}
	}

	.container:first-of-type {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

}
// }}}
// Main {{{
main {
	min-height: 100vh;
	overflow: hidden;
}

.container {
	margin: 0 auto;

	@media (min-width: @media_xs) {
		& {
			max-width: 530px;
		}
	}

	@media (min-width: @media_sm) {
		& {
			max-width: 780px;
		}
	}

	@media (min-width: @media_m) {
		& {
			max-width: 960px;
		}
	}

	@media (min-width: @media_l) {
		& {
			max-width: 1140px;
		}
	}
}

.row {
	display: flex;
	flex-wrap: wrap;
	margin: 50px -15px -15px;
}

section {
	padding: 50px 15px;
}
// }}}
// Intro {{{
main section:first-child {
	padding-top: 225px;
	color: white;

	@media (max-width: @media_l) AND (min-width: @media_m) {
		padding-top: 275px;
	}

	@media (max-width: @media_m) {
		padding-top: 100px;
	}
}

.intro {
	.row {
		margin: 0 -15px;
	}

	> .row > .left {
		width: calc((100% / 12) * 7);
		padding: 0 45px 0 15px;

		@media (max-width: @media_m) {
			padding: 0 15px !important;
		}

		p {
			text-align: left;
		}

		.heading {
			@media (max-width: @media_m) {
				text-align: center;
			}

			> * {
				display: inline;
				font-size: 50px;
				line-height: 50px;
				font-family: 'impactreg', sans-serif;
				letter-spacing: 1px;
				color: white;

				@media (max-width: @media_m) {
					font-size: 35px;
				}
			}

			span {
				display: block;
				font-size: 34px;
				font-weight: normal;
				text-align: left;

				@media (max-width: @media_m) {
					text-align: center;
				}
			}
		}
		
		&:first-child img {
			display: block;
			height: 140px;
			margin: 30px auto 0 auto;
			object-fit: contain;
		}
	}

	.right {
		position: relative;
		width: calc((100% / 12) * 5);
		padding: 0 15px 0 45px;

		img.icon {
			position: absolute;
			left: 0; right: 0; bottom: 0; top: 0;
			margin: auto;
			height: 200px;
		}

		.card-small-webhotel {
			border: none;
		}
	}

	@media (max-width: @media_m) {
		> .row > .left, > .row > .right {
			width: 100% !important;
		}

		.right {
			padding: 0 !important;
		}
	}

	p {
		margin-top: 30px;
		line-height: 24px;
		font-size: 18px;
	}
}
// }}}
// Site Index {{{
.site-index {
	padding-bottom: 50px;
	color: white;

	.col {
		display: flex;
		flex-wrap: wrap;

		> * {
			width: 50%;

			&:first-of-type {
				padding-right: 40px;
			}
		}
	}

	a {
		margin-bottom: 10px;
		font-size: 18px;
		text-decoration: none;
		color: white;

		&:hover {
			text-decoration: underline;
		}
	}
}
// }}}
// Question/Anwsers {{{
#qa-container {
	position: relative;
	height: 130px;

	section {
		position: absolute;
		top: 0;
		width: 100%;
		padding-top: 30px;
		background: none;
		background-color: var(--gray);
		transition: bottom 0.3s;
		z-index: 1;
	}

	ul {
		margin-left: 20px;

		li {
			color: var(--black);
		}
	}

	.text-subcontainer > a {
		margin: 15px;
	}

	p.heading {
		margin-bottom: 50px;
		font-size: 30px;
		text-align: center;
		color: white;
	}

	img.arrow {
		display: block;
		width: 50px;
		margin: 0 auto 20px auto;
		cursor: pointer;
		transition: transform 0.3s;
	}

	img.arrow.flip {
		transform: rotate3d(1, 0, 0, 180deg);
	}

	.column {
		float: left;
		width: 50%;

		@media (max-width: @media_m) {
			& {
				width: 100%;
			}
		}
	}

	p {
		color: var(--black);
	}
}
// }}}
// Cards {{{
// Generic {{{
.card-small-webhotel, .card-medium-webhotel, .card-question-anwser, .card-webpackage, .card-webpackage-specs, .card-server {
	margin: 0 15px 30px 15px;
	border: 1px solid lightgray;
    box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.2);
}

@media (max-width: 413px) {
	main .card-small-webhotel, main .card-medium-webhotel, main .card-webpackage, main .card-webpackage-specs, main .card-server {
		padding: 15px;
	}
}

@media (max-width: 827px) {
	.card-small-webhotel, .card-medium-webhotel, main .card-question-anwser, main .card-webpackage, main .card-webpackage-specs, main .card-server {
		width: calc(100% - 30px);
	}
}

@media (max-width: 1200px) and (min-width: 828px) {
	.card-small-webhotel, .card-medium-webhotel, main .card-webpackage, main .card-webpackage-specs, main .card-server {
		width: calc((100% / 2) - 30px);
	}
}

@media (min-width: 1200px) {
	.card-small-webhotel, .card-medium-webhotel, main .card-webpackage, main .card-webpackage-specs, main .card-server {
		width: calc((100% / 3) - 30px);
	}
}

.card-medium-webhotel .over a:first-of-type,
.card-server .over a:first-of-type {
	float: left;
	height: 50px;
	max-width: 70%;

	> img {
		height: 100%;
		max-width: 100%;
		object-fit: contain;
	}
}

.card-medium-webhotel .over a:last-of-type,
.card-server .over a:last-of-type {
	line-height: 26px;
	font-weight: bold;
	color: var(--blue);
	border-bottom: 2px solid var(--blue);
	text-decoration: none;
}

.card-medium-webhotel .bottom, .card-server .bottom {
	width: 100%;
	padding-top: 15px;
	border-top: 2px solid var(--orange);
}

.card-medium-webhotel a.order-link {
	float: left;
	width: 100%;
	margin-top: 15px;
	padding: 15px 0;
	font-size: 22px;
	text-align: center;
	text-decoration: none;
	color: white;
	background-color: var(--orange);
	border-radius: 5px;
    font-weight: bold;
    box-shadow: 0 5px 0 0 rgb(134, 100, 24);
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
}

.card-webpackage, .card-webpackage-specs, .card-server {
	display: flex;
	flex-direction: column;
	padding: 15px;
	background-color: white;
	border-radius: 8px;
}

.card-webpackage div, .card-webpackage-specs div, .card-server .bottom {
	height: 100%;
}

.card-webpackage p.price, .card-webpackage-specs p.price {
	margin-top: 25px;
}

main .card-webpackage h2, main .card-webpackage-specs h2 {
	padding: 0;
}

.card-webpackage-specs p, .card-server p {
	margin: 15px 0;
	text-align: left;
}

p.price {
	margin-bottom: 0;
	font-size: 21px;
	font-weight: bold;
	color: var(--red);
}

.card-webpackage p.price span, .card-medium-webhotel p.price span, .snippet-card p.price span {
	margin-left: 20px;
	text-decoration: line-through;
	color: gray;
}
// }}}
// info {{{
.info {
	position: relative;
	padding-bottom: 25px;
}

.info.orange {
	background-color: var(--backgroundorange);
}

.info.blue {
	background-color: var(--backgroundblue);
}

.info.turquoise {
	background-color: var(--turquoise);
}

.info.green {
	background-color: var(--backgroundgreen);
}

.info.gray {
	background-color: var(--lightgray);
}

.info .title p,
.info .title h2,
.info .title h3,
.info .title h4 {
	padding: 50px 0 25px;
	font-size: 30px;
}

@media (max-width: 575px) {
	.info .title {
		margin: 0 15px;
	}
}

.info.orange .title {
	border-bottom: 2px solid var(--orange);
}

.info.orange .title p,
.info.orange .title h2,
.info.orange .title h3,
.info.orange .title h4 {
	color: var(--orange);
}

.info.blue .title {
	border-bottom: 2px solid var(--blue);
}

.info.blue .title p,
.info.blue .title h2,
.info.blue .title h3,
.info.blue .title h4 {
	color: var(--blue);
}

.info.green .title {
	border-bottom: 2px solid var(--green);
}

.info.green .title p,
.info.green .title h2,
.info.green .title h3,
.info.green .title h4 {
	color: var(--green);
}

.info.gray .title {
	border-bottom: 2px solid var(--gray);
}

.info.gray .title p,
.info.gray .title h2,
.info.gray .title h3,
.info.gray .title h4 {
	color: var(--gray);
}

.info p.desc {
	margin: 25px 0;
}

@media (max-width: 575px) {
	.info p.desc {
		padding: 0 15px;
	}
}

.info p {
	margin: 0 0 25px;
	line-height: 26px;
}

.info h3 {
	margin: 0 0 10px;
}

.info .container .row {
	margin: 25px -15px 0;
	padding-top: 15px;
}

.info .container .row .col {
	width: calc((100% / 2) - 30px);
	margin: 0 15px 0px 15px;
}

@media (max-width: 1199px) {
	.info .container .row .col {
		width: calc((100% / 2) - 30px);
	}
}

@media (max-width: 827px) {
	.info .container .row .col {
		width: 100%;
	}
}

.info .container .row .col p {
	text-align: justify;
}

@media (max-width: 1199px) {
	.info .container .row .col p {
		text-align: left;
	}
}

@media (max-width: 575px) {
	.info .container .row {
		margin: 25px 0 0;
	}
}
// }}}
// card-small-webhotel {{{
.card-small-webhotel {
	padding: 15px 30px;
	color: var(--black);
	background-color: white;
	border-radius: 8px;
	box-shadow: 3px 3px 20px 5px rgba(0, 0, 0, 0.15);
}

.card-small-webhotel .over a.info-link {
	float: right;
	line-height: 50px;
	font-weight: bold;
	color: var(--blue);
}

.card-small-webhotel .over p {
	float: left;
	clear: left;
	margin: 5px 0;
	font-size: 16px;
	text-align: left;
}

.card-small-webhotel .over p span,
.card-small-webhotel .over h2 {
	display: block;
    border-bottom: 2px solid var(--black);
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.card-small-webhotel .over h2 {
	clear: both;
	margin-bottom: -15px;
	margin-top: 45px;
	padding-top: 25px;
}

.card-small-webhotel .over p:first-of-type {
	margin: 15px 0 5px 0;
}

.card-small-webhotel .over p:last-of-type {
	margin: 25px 0;
}

.card-small-webhotel .left {
	float: left;
	width: auto;
	padding: 0;
	clear: left;
	text-align: left;
}

.card-small-webhotel .right {
	float: right;
	width: auto;
	padding: 0;

	@media (max-width: 1200px) {
		width: 100%;
		margin-top: 20px;
		text-align: center;
	}
}

.card-small-webhotel .right a {
	display: block;
	padding: 10px 15px;
	font-size: 20px;
	color: white;
	background-color: var(--orange);
	border-radius: 5px;
	text-decoration: none;
    font-weight: bold;
    box-shadow: 0 5px 0 0 rgb(134, 100, 24);
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
}

.card-small-webhotel .right p {
	margin-top: 15px;
	text-align: center;
	font-weight: bold;
	color: var(--red);
}

.card-small-webhotel .right p.old {
	margin-top: 5px; 
	text-decoration: line-through; 
	color: gray;
}

@media (max-width: 413px) {
	.card-small-webhotel .right {
		float: left;
		clear: left;
		width: 100%;
		margin-top: 10px;
	}

	.card-small-webhotel .right a {
		text-align: center;
	}
}

.card-small-webhotel .over img {
	float: left;
	height: 50px;
	max-width: 100%;
	object-fit: contain;
}

.checkbox-field {
	margin-bottom: 10px;
}

.checkbox {
	position: relative;
	display: inline-block;
	height: 30px;
	width: 30px;
	border: 3px solid gray;
	border-radius: 5px;
}

.checkbox img {
	position: absolute;
	bottom: 0; left: 0; right: 0;
	height: 35px;
	margin: auto;
}

.checkbox img.unchecked {
	top: 1px;
}

.checkbox-field p {
	display: inline-block;
	margin: 0 0 0 10px;
	line-height: 30px;
	font-size: 16px;
	vertical-align: top;
	color: var(--blue);
}
// }}}
// card-medium-webhotel {{{
.card-medium-webhotel {
	display: flex;
	flex-direction: column;
	padding: 15px;
	text-align: left;
	color: var(--black);
	background-color: white;
	border-radius: 8px;

	.over {
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;
		padding-bottom: 10px;

		img.corner-ribbon {
			position: absolute;
			left: -20px;
			width: 90px;
			top: -20px;
		}

		p.corner-ribbon {
			position: absolute;
			left: -23px;
			width: 90px;
			top: -12px;
			font-family: sans-serif;
			font-weight: bold;
			color: white;
			transform: rotate(-45deg);
		}
	}
}

.card-medium-webhotel .middle {
	height: 100%;
	width: 100%;
	margin-top: 10px;
	padding-bottom: 20px;

	p {
		margin: 0;
	}
}

.card-medium-webhotel .middle p span,
.card-medium-webhotel .middle h2 {
	display: block;
    border-bottom: 2px solid var(--black);
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.card-small-webhotel .over h2,
.card-medium-webhotel .middle h2 {
	font-size: 16px;
	font-family: segoe ui, sans-serif !important;
	font-weight: bold;
	letter-spacing: 0px;
	text-align: left;
	color: var(--black);
}

.card-medium-webhotel .left {
	float: left;
}

.card-medium-webhotel .right {
	float: right;
}

.card-medium-webhotel {
	.bottom {
		margin-bottom: 15px;

		&.noBorder {
			padding-top: 0;
			border: none;

			p.price {
				margin-top: 0;
			}
		}

		&.noBorderPadding {
			height: 100%;
			border: none;
		}

		p {
			line-height: 26px;
			margin: 0 0 5px;

			&.price {
				margin-top: 20px;
			}
		}

		.right {
			text-align: right;
		}
	}
}

@media (max-width: 413px) {
	.card-medium-webhotel .middle .right {
		float: left;
		clear: left;
	}
}
// }}}
// card-question-anwser {{{
.card-question-anwser {
	padding: 0 0 20px 0;
	background-color: var(--orange);
	border-radius: 8px;
	transition: height 0.3s;
	cursor: pointer;
}

.card-question-anwser strong {
	display: block;
	position: relative;
	padding: 20px 30px 0 30px;
	text-align: center;
	font-size: 22px;
	color: white;
	transition: padding 0.3s;
	user-select: none;
}

.card-question-anwser strong img {
	position: absolute;
	top: 28px;
	right: 15px;
	height: 12px;
	transition: transform 0.2s;
}

.card-question-anwser strong img.rotate {
	transform: rotate(180deg);
}

.card-question-anwser .text-container {
	position: relative;
	height: 0px;
	background-color: white;
	transition: height 0.3s;
	overflow: hidden;
	cursor: initial;
}

.card-question-anwser .text-container .text-subcontainer {
	position: absolute;
	bottom: 0;
	width: 100%;
}

.card-question-anwser .text-container .text-subcontainer p {
	margin: 15px;
	line-height: 22px;
}
// }}}
// card-webpackage {{{
.info .container .row .card-webpackage div p,
.card-webpackage div p {
	margin-top: 15px;
}
// }}}
// }}}
// Trustpilot container {{{
.trustpilot-container {
	margin-top: 100px;

	@media (max-width: 992px) {
		margin-top: 50px;
	}
}
// }}}
